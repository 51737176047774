// Radio / checkbox color picker
.custom-color {
  display: inline-flex;
  flex-wrap: wrap;
  margin: -2px;
  .color-item {
    height: 1rem;
    margin: 2px;
    &.color-item-light {
      label {
        box-shadow: 0 0 2px 0 #bbb inset;
      }
      .custom-control-input:checked ~ label::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23333' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
      }
    }
  }
  label {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }
  .custom-control-input {
    &:checked ~ label::after {
      content: '';
      width: 1rem;
      height: 1rem;
      display: block;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
      // background-image: $custom-checkbox-indicator-icon-checked;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: $custom-control-indicator-bg-size;
    }
    &:disabled ~ label {
      opacity: $btn-disabled-opacity;
      cursor: not-allowed;
    }
  }
  &.custom-color-lg {
    .color-item {
      height: 1.5rem;
    }
    label,
    .custom-control-input:checked ~ label::after {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.custom-spinner {
  display: inline-flex;
  input[type="number"] {
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    text-align: center;
    border: 0;
    box-shadow: none;
    &.form-control {
      width: calc(100% - 76px);
    }
    &.form-control-sm {
      width: calc(100% - 62px);
    }
    &.form-control-lg {
      width: calc(100% - 96px);
    }
  }
  .btn {
    flex: none;
    z-index: 1;
  }
  &.custom-spinner-vertical {
    display: inline-block;
    input[type="number"] {
      padding-left: 0;
      padding-right: 0;
      &.form-control {
        width: 38px;
      }
      &.form-control-sm {
        width: 31px;
      }
      &.form-control-lg {
        width: 48px;
      }
    }
  }
}
