.noty_theme__mint.noty_bar {
  position: relative;
  overflow: hidden;
  margin: 4px 0;
  box-shadow: rgba(0, 0, 0, .298039) 0 0 5px 0;
  .noty_progressbar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    opacity: .2;
    background-color: #000;
    filter: alpha(opacity=20);
  }
  .noty_body {
    font-size: 15px;
    padding: 1.25em;
  }
  .noty_buttons {
    padding: 0 10px .5em 10px;
  }
}
.noty_theme__mint.noty_type__alert,
.noty_theme__mint.noty_type__notification {
  color: $body-color;
  background-color: rgba(#fff, .8);
}
.noty_theme__mint.noty_type__warning {
  color: #fff;
  background-color: rgba($warning, .8);
}
.noty_theme__mint.noty_type__error {
  color: #FFF;
  background-color: rgba($danger, .8);
}
.noty_theme__mint.noty_type__info,
.noty_theme__mint.noty_type__information {
  color: #FFF;
  background-color: rgba($info, .8);
}
.noty_theme__mint.noty_type__success {
  color: #fff;
  background-color: rgba($success, .8);
}
.noty_theme__mint.noty_type__pink {
  color: #fff;
  background-color: rgba(#e83e8c, .8);
}

#noty_layout__topRight {
  top: $grid-gutter-width / 2;
  right: $grid-gutter-width / 2;
  width: 310px;
}