.list-group-sm .list-group-item {
  padding: ($list-group-item-padding-y / 2) ($list-group-item-padding-x / 2);
}
.list-group-no-border .list-group-item {
  border: 0;
  margin-bottom: 0;
}
.list-group-striped .list-group-item:nth-of-type(odd) {
  &:not(.list-group-item-action) {
    background-color: $list-group-item-stripped-bg;
  }
  &.list-group-item-action:not(:hover):not(:focus) {
    background-color: $list-group-item-stripped-bg;
  }
}
.list-group-item.has-icon {
  display: flex;
  align-items: center;
}