.font-size-base {
  font-size: $font-size-base;
}
.font-size-sm {
  font-size: $font-size-sm;
}
.font-size-lg {
  font-size: $font-size-lg;
}
.font-condensed {
  font-family: 'Roboto Condensed', sans-serif;
}
.text-decoration-underline {
  text-decoration: underline;
}