.footer {
  background-color: $footer-bg;
  border-top: 2px solid $border-color;
  padding-top: 1rem;
  .d-grid > * {
    margin-bottom: 1.5rem;
  }
  .list-group-sm .list-group-item {
    background-color: $footer-bg;
    padding-left: 0;
    @include hover-focus {
      background-color: inherit;
      color: $primary;
      font-weight: bolder;
    }
  }
}

.download-app {
  display: inline-flex;
  margin: 0 .5rem .5rem 0;
  padding: 10px 15px;
  border: 1px solid $border-color;
  border-radius: 5px;
  line-height: 1.2;
  transition: background-color .1s;
  img {
    width: 30px;
    margin-right: 15px;
  }
  .media-body {
    margin-top: -4px;
  }
  h5 {
    color: $body-color;
    margin-bottom: 0;
  }
  small {
    color: $text-muted;
  }
  &:hover {
    text-decoration: none;
    background-color: #f8f8f8;
  }
}

.copyright {
  text-align: center;
  padding: .5rem 0;
  background-color: $copyright-bg;
  border-top: 1px solid $border-color;
  font-size: 90%;
}