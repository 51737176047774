// Size
.noUi-horizontal {
  height: $noui-size;
}
.noUi-vertical {
  width: $noui-size;
}

// Bar
.noUi-target {
  border: 0;
  background: #dee2e6;
  box-shadow: none;
}
.noUi-target,
.noUi-connects {
  border-radius: $border-radius;
}
.noUi-connect {
  background: $component-active-bg;
}

// Handle
.noUi-handle {
  border: 0;
  background-color: $component-active-bg;
  box-shadow: none;
  &::before,
  &::after {
    display: none;
  }
  &:focus {
    outline: 0;
  }
  .noUi-horizontal & {
    width: $noui-handle-width;
    height: $noui-handle-height;
    cursor: ew-resize;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc((#{$noui-size} - #{$noui-handle-height}) / 2);
    right: -($noui-handle-width / 2);
  }
  .noUi-vertical & {
    width: $noui-handle-height;
    height: $noui-handle-width;
    cursor: ns-resize;
    right: calc((#{$noui-size} - #{$noui-handle-height}) / 2);
    top: -($noui-handle-width / 2);
  }
}

// Tooltip
.noUi-tooltip {
  display: flex;
  color: color-yiq($component-active-bg);
  border: 0;
  background-color: $component-active-bg;
  font-size: 12px;
  padding: 0 5px;
  &::after {
    content: '';
    border: 4px solid;
    display: inline-block;
    position: absolute;
  }
  .noUi-horizontal & {
    position: static;
    transform: none;
    margin-bottom: 46px;
    &::after {
      border-color: $component-active-bg transparent transparent;
      top: -6px;
      left: 50%;
      margin-left: -4px;
    }
  }
  .noUi-vertical & {
    margin-right: 3px;
    &::after {
      border-color: transparent transparent transparent $component-active-bg;
      right: -8px;
      top: 50%;
      margin-top: -4px;
    }
  }
}